// extracted by mini-css-extract-plugin
export var articleTitle = "iot-module--articleTitle--434e2";
export var button = "iot-module--button--5e4af";
export var firstSectionContainer = "iot-module--firstSectionContainer--4ff09";
export var grid = "iot-module--grid--54921";
export var headerText = "iot-module--headerText--6d153";
export var heading = "iot-module--heading--d626d";
export var headingDescription = "iot-module--headingDescription--ab8a7";
export var headingDescriptionMobile = "iot-module--headingDescriptionMobile--55463";
export var heroImage = "iot-module--heroImage--97a80";
export var left = "iot-module--left--0014f";
export var markdown = "iot-module--markdown--d74db";
export var mobileTitle = "iot-module--mobileTitle--762a9";
export var right = "iot-module--right--799f1";
export var sectionContainer = "iot-module--sectionContainer--40140";
export var sectionReadMore = "iot-module--sectionReadMore--9288a";
export var smallImage = "iot-module--smallImage--fcae2";
export var subtitle = "iot-module--subtitle--1ac48";
export var text = "iot-module--text--8a5ec";
export var title = "iot-module--title--9e8ea";