import * as React from "react";
import { graphql } from "gatsby";

import Layout from "../../components/layout";
import Button from "../../components/ui/button";
import Title from "../../components/ui/title";
import Section from "../../components/layout/section";
import TwoContainersLayout from "../../components/layout/twoContainersLayout";

import * as styles from "./iot.module.scss";
import { StaticImage } from "gatsby-plugin-image";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import Bulb from "../../assets/svg/bulb.svg";
import classNames from "classnames";
import { SEOv2 } from "../../components/seov2";

const IoT = ({ data }) => (
  <Layout>
    <Section type="lightest-green" className={styles.firstSectionContainer}>
      <TwoContainersLayout>
        <div className={styles.left}>
          <span className={styles.heading}>
            Какво е “IoT” и за какво служи?
          </span>
          <span className={styles.headingDescription}>
            <ReactMarkdown
              className={classNames(styles.markdown, styles.headerText)}
            >
              {data.allStrapiIot.edges[0].node.headerText.data.headerText}
            </ReactMarkdown>
          </span>
        </div>
        <div className={styles.right}>
          <StaticImage
            className={styles.heroImage}
            alt="iot hero image"
            objectFit="contain"
            src="../../assets/images/iot.png"
            placeholder="blurred"
          />
          <span className={styles.headingDescriptionMobile}>
            <ReactMarkdown
              className={classNames(styles.markdown, styles.headerText)}
            >
              {data.allStrapiIot.edges[0].node.headerText.data.headerText}
            </ReactMarkdown>
          </span>
        </div>
      </TwoContainersLayout>
    </Section>
    <Section type="white" className={styles.sectionContainer}>
      <div className={styles.grid}>
        <Bulb className={styles.smallImage} />
        <Title className={styles.articleTitle}>
          {data.allStrapiIot.edges[0].node.iotTitle}
        </Title>
        <ReactMarkdown className={classNames(styles.markdown, styles.text)}>
          {data.allStrapiIot.edges[0].node.iot.data.iot}
        </ReactMarkdown>
      </div>
    </Section>
    <Section type="nearly-black" className={styles.sectionReadMore}>
      <span className={styles.subtitle}>Концепцията “Умен Дом”</span>
      <Title className={styles.title} inverted={true}>
        Видове автоматизации тип “Умен Дом”
      </Title>
      <Button
        className={styles.button}
        title={"Прочети"}
        inverted
        to="/smart-home/types"
      />
    </Section>
    <Section type="white" className={styles.sectionContainer}>
      <Title className={styles.mobileTitle}>Свържете се с нас</Title>
      <Button
        className={styles.button}
        title={"Свържете се с нас"}
        to="/contacts"
      />
    </Section>
  </Layout>
);

export const query = graphql`
  query {
    allStrapiIot {
      edges {
        node {
          iotTitle
          iot {
            data {
              iot
            }
          }
          headerText {
            data {
              headerText
            }
          }
        }
      }
    }
  }
`;

export default IoT;

export const Head = () => <SEOv2 title="Какво е “IoT" previewImg="iot" />;
